// .filtericon{
//     position: absolute;
//     right: 10px;
//     color: rgb(118, 118, 118);
//     z-index: 99999 !important;
// }
.filterinput{
    padding-left: 370px;
}
.btnEdit{
    border: 1px solid #458591;
    border-radius: 4px;
    padding: 10px 22px;
    background: #606cff; 
    color: #fff;
    background-color: transparent;
}
.btnEdit:hover{
    background: #fff;
}


.form-check-input:focus {
    border-color: #fff;
    outline: 0;
    // box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    background:#515862
}
// .form-check-input:checked[type=radio] {
//     background-image:none !important;
// }
 
// .form-check-input:checked {
//     background-color: #458591; 
//     border-color: #458591; 
// }
.edit-btn{
    border-color:rgba(105, 108, 255, 0.5); /* #696CFF with 50% opacity */
    border-radius: 30px;
    background-color: #fff;
    color: #515862;
    font-size: 15px;
    font-weight: 500;
}
// .truncate-text {
//     max-width: 150px; /* Adjust the maximum width as needed */
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     position: relative;
//   }
  
  .tooltip::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }
  .iWyFZZ div:first-child {
    white-space: nowrap;
    overflow: hidden;
    // text-overflow: clip;
}

.iWyFZZ:hover div:first-child {
    white-space: normal;
    overflow: visible;
    // text-overflow: initial;
}

.iWyFZZ:hover div:first-child::before {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    // transition: opacity 0.3s ease, visibility 0.3s ease;
}

.iWyFZZ:hover div:first-child::before {
    opacity: 1;
    visibility: visible;
}
.kHguQu {
    position: static !important;
}