.wrapper-layout {
    display: flex;
    width: 100%;
}

.wrapper-main {
    display: flex;
    flex: 1;
    margin-left: 15em;
    margin-top: 5em;
    overflow-y:auto;
}

.wrapper-card {
    padding: 30px;
    width: 100%;
    min-height: 80vh;
}

@media (min-width: 566px) and (max-width:1024px) {
    .wrapper-main {
        margin-left: 0em;
    }
}

@media (min-width: 360px) and (max-width:565px) {
    .wrapper-main {
        margin-left: 0em;
    }
}


@media (min-width: 360px) and (max-width:767px) {
    .wrapper-main {
        padding: 10px; /* Reduce padding for smaller screens */
    }
}
    // @media (min-width: 768px) and (max-width: 1024px) {
    //     padding: 15px; /* Adjust padding for tablets */
    // }
    @media (min-width: 768px) and (max-width:1024px) {
        .wrapper-card {
            margin-left: 45px;

        }
    }