.card{
  background-color: #fff;
  border-radius: 15px;
  min-height: 100vh;
}

.card-body-first{
  cursor: pointer;
  border-radius: 30px;
  background-color:#fff;
  color: #696CFF;
  border: 1px solid  rgba(149, 150, 224, 0.5);
}
.card-body-first:hover{
  background-color:  rgba(149, 150, 224, 0.2);
}
.card-body-test {
    background: #696CFF ;
    color: #fFF
}

.card-body-second{
  cursor: pointer;
  border-radius: 30px;
  background-color:#fff;
  color: #696CFF;
  border: 1px solid  rgba(149, 150, 224, 0.5);
}
.card-body-second:hover{
  background-color:  rgba(149, 150, 224, 0.2);
}
.card-body-test {
    background: #696CFF ;
    color: #fFF
}
.h-para, .h-num{
  font-size: 20px;
  color:#696cff
}
.card-body-sec{
    background: #fFF;
}

.person-l{
  color: #696CFF;
  font-size: 2rem;
}
.icon_btn{
    font-size: 1.5rem;
}
.card_font{
    color: #fff;
    font-size: 15px;
}


@media (max-width: 767px) {
  .col-md-6 {
      flex-direction: column; /* Change the flex direction to column for mobile screens */
  }

  .card-body-second {
      order: 1; /* Change the order of .card-body-second to 1, so it appears below .card-body-first */
  }

  .card-body-first,
  .card-body-second {
      width: 100%; /* Make both card bodies take the full width on mobile screens */
      margin-right: 0; /* Remove right margin on mobile screens */
      margin-bottom: 0px; /* Add some bottom margin for spacing on mobile screens */
      margin-top: 0rem !important;
  }

  .mx-5 {
      margin-right: 0rem !important;
      margin-left: 0rem !important;  /* Remove horizontal margin on mobile screens */
  }

  
  .card-body-second {
    
    margin-top: 2rem !important;
      // margin: 35px; /* Add some vertical margin for spacing on mobile screens */
  }
}