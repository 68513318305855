.topbar{
    padding: 0px;
}
.btn-add{
    border: none;
    border-radius: 30px;
    color: white;
    background-color: #696CFF;
}
.btn-add:hover {
    background-color: #5e5e7e; /* Grey color when hovering */
  }
  @media (max-width: 767px) {
    .col-md-12 {
     justify-content: center!important;
     margin-top: 2rem;
    }
}