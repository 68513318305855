.rdt_TableRow{
    background: none !important;
    border: none !important;
    font-size: 12px !important ;
    font-weight: 500;
    background-color:#696CFF
}


.rdt_TableRow:nth-child(even) {
    background: #fff !important;
    // background-color:rgba(0, 0, 0, 0.5);
    background-color:#696CFF
}