.textnew{
    color: #515862;
}
.modal-dd{
    color: #515862;
    min-width: 54pc;
}
.m-header{
    color: #696CFF;
}
.btn-modal{
    color: #fff;
    border-radius: 20px;
    background-color: #696CFF;
}
.btn-modal:hover{
    color: #fff;
    background-color: #515862;
}