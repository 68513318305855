.s-layout {
    display: flex;
    width: 100%;
    position: fixed;
    z-index: 3;
  }
  
  .s-layout__content {
    display: flex;
    flex: 1;
    margin-left: 15em;
  }
  
  .header-bg {
    min-height: 75px;
    display: flex;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    // border-bottom: 1px solid #696CFF;
    color: #696CFF;
    padding-left: 1.8em;
  }
  
  @media (min-width: 566px) and (max-width:1024px) {
    .s-layout__content {
      margin-left: 0em;
    }
  }
  
  @media (min-width: 360px) and (max-width:565px) {
    .s-layout__content {
      margin-left: 0em;
    }
  }
  
  .dropdown-button-hide {
    background: transparent !important;
    border: none !important;
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .s-layout__content {
      margin-left: 3rem;
       display:flex; /* Stack content vertically on tablets */
        align-items: center; /* Center items in the main axis on tablets */
        text-align:start; /* Center text within its container on tablets */
    }

    .s-layout__content > div {
        margin-bottom: 10px; /* Add spacing between the two divs on tablets */
    }

    /* Add additional styles as needed */
}
  .header-dropdown{
    background: #696CFF;
  
    // &:hover{
    //   background: #222;
    //   color: #fff;
    //   transition:0ms;
    // }
  }
  
  .gr-not{
    color: #696CFF;
  }
  .icon-bg{
    background-color: #fafafa;
    border-radius: 2px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cursor{
    cursor: pointer;
  }
  
  .user-profile{
    width: 40px;
    height: 40px ;
    border-radius: 50%;
    background: #696CFF;
  }
  .logot{
    color: #FFF;
  }