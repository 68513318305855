.add-btn{
    border: none;
    border-radius: 16px;
    padding: 5px 10px;
    background: #696CFF ;
    color: #fff;
}
.add-btn:hover{
    background: #5e5e7e ;
}

.card-btn{
    min-width: 303px;
}