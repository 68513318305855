.search {
    border: 1px solid #E7E7E7;
    border-radius: 25px;
    // padding: 12px;
    background: #FFF;
    min-height: 44px;
    // width: 44px;
    border-color: 1px solid #515862;
}

.search-icon {
    top: 20%;
    right: 45%;
    color: #515862;
}
.search-style{
    padding-left: 30px;
}
@media (max-width: 767px) {
    /* Adjust styles for screens up to 767px (typical mobile screens) */
    .search-icon {
        top: 20%; /* Adjust the top position for mobile screens */
        right: 90%; /* Adjust the right position for mobile screens */
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .search-icon {
        top: 20%; /* Adjust the top position for tablet screens */
        right: 25%; /* Adjust the right position for tablet screens */
    }
}