.inputs-label{
    // font-family: 'Inter' ;
    color: #515862;
    font-weight: 500;

}
.inputs{
    min-height: 55px;
    border-radius: 14px;
    border: 1px solid #D9D9D9;
    background: #FFF;
}
.error-text{
    color:rgba(255, 0, 0, 0.829)
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}